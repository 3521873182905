.mob {
  display: none;
  position: absolute;
  right: 0;
  top: -10px;
  z-index: 2;

  @media screen and (max-width: 1180px) {
    display: block;
  }
}

.mobBtn {
  width: 35px;
  height: 23px;
  position: relative;
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.mobBtn {
  &.open {
    span {
      background-color: #000;
    }
  }

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #000;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
}

.mobBtn span:nth-child(1) {
  top: 0;
}

.mobBtn span:nth-child(2),
.mobBtn span:nth-child(3) {
  top: 10px;
}

.mobBtn span:nth-child(4) {
  top: 20px;
}

.mobBtn.open span:nth-child(1) {
  top: 18px;
  width: 0;
  left: 50%;
}

.mobBtn.open span:nth-child(2) {
  transform: rotate(45deg);
}

.mobBtn.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.mobBtn.open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}

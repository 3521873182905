._container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 64px;

  @media screen and (max-width: 1180px) {
    padding: 0 32px;
  }

  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }
}

$large: "(max-width: 1699px)";
$desktop-md: "(max-width: 1549px)";
$desktop: "(max-width: 1319px)";
$desktop-sm: "(max-width: 1180px)";
$tablet: "(max-width: 1023px)";
$phone: "(max-width: 767px)";

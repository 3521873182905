@keyframes image1 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes image2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes image3 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes image4 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes image5 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes image6 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}

.utilitiesImg {
  position: relative;
  width: 100%;
  height: 857px;
  animation: fadeIn ease-in-out 3s alternate;
  pointer-events: none;

  @media screen and (max-width: 1549px) {
    margin-bottom: -110px;
    transform: scale(0.85);
    margin-left: -80px;
  }
  @media screen and (max-width: 1319px) {
    margin-top: -100px;
    transform: scale(0.6);
  }
  @media screen and (max-width: 1180px) {
    margin-left: -150px;
  }
  @media screen and (max-width: 1023px) {
    order: 0;
    align-self: center;
    margin-left: 0;
  }
  @media screen and (max-width: 767px) {
    margin-left: -50px;
    transform: scale(0.5);
  }
  @media screen and (max-width: 567px) {
    margin-top: -200px;
    margin-left: -180px;
    margin-bottom: -150px;
    transform: scale(0.43);
  }

  &::before {
    content: "";
    position: absolute;
    top: -100px;
    left: 150px;
    width: 667px;
    height: 1161px;
    pointer-events: none;
    background: url('./Assets/Vector.svg') center no-repeat;

    @media screen and (max-width: 767px) {
      margin-left: -150px;
    }
  }

  img {
    position: absolute;
    
    &:nth-child(1) {
      top: 0;
      left: 55px;
      z-index: 5;
      animation: image1 3s ease-in-out 1s infinite alternate;
    }

    &:nth-child(2) {
      left: 452px;
      top: 233px;
      z-index: 6;
      animation: image2 3s ease-in-out 0.4s infinite alternate;
    }

    &:nth-child(3) {
      z-index: 9;
      top: 260px;
      left: 0;
      animation: image3 2s ease-in-out 1.9s infinite alternate;
    }

    &:nth-child(4) {
      z-index: 7;
      bottom: 0;
      left: 250px;
      animation: image4 3s ease-in-out 1.7s infinite alternate;
    }

    &:nth-child(5) {
      z-index: 8;
      top: 370px;
      left: 115px;
      animation: image5 3s ease-in-out 1.3s infinite alternate;
    }

    &:nth-child(6) {
      z-index: 10;
      bottom: 100px;
      left: 320px;
      animation: image6 2s ease-in-out 2.5s infinite alternate;
    }
  }
}
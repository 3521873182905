@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Regular"), local("Gilroy-Regular"),
    url("/public/fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Bold"), local("Gilroy-Bold"),
    url("/public/fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Black"), local("Gilroy-Black"),
    url("/public/fonts/Gilroy/Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Light"), local("Gilroy-Light"),
    url("/public/fonts/Gilroy/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Semibold"), local("Gilroy-Semibold"),
    url("/public/fonts/Gilroy/Gilroy-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Medium"), local("Gilroy-Medium"),
    url("/public/fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Black Italic"), local("Gilroy-BlackItalic"),
    url("/public/fonts/Gilroy/Gilroy-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Extrabold"), local("Gilroy-Extrabold"),
    url("/public/fonts/Gilroy/Gilroy-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Heavy"), local("Gilroy-Heavy"),
    url("/public/fonts/Gilroy/Gilroy-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Thin"), local("Gilroy-Thin"),
    url("/public/fonts/Gilroy/Gilroy-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

html,
body {
  width: 100%;
  height: 100%;
  border: 0;
  font-family: "Gilroy", sans-serif;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

div,
span,
object,
iframe,
img,
table,
caption,
thead,
tbody,
tfoot,
tr,
td,
article,
aside,
canvas,
details,
figure,
hgroup,
menu,
nav,
footer,
header,
section,
summary,
mark,
audio,
video {
  border: 0;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
code,
del,
dfn,
em,
ins,
q,
samp,
small,
strong,
sub,
sup,
b,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
legend,
label {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

article,
aside,
canvas {
  display: block;
}

figure {
  display: block;

  img {
    display: block;
  }
}

figcaption,
hgroup,
footer,
header,
nav,
section,
audio,
video {
  display: block;
}

table {
  border-collapse: separate;
  border-spacing: 0;

  caption,
  th,
  td {
    text-align: left;
    vertical-align: middle;
  }
}

a {
  color: inherit;
  font: inherit;

  img {
    border: 0;
  }

  text-decoration: none;
}

ul,
li {
  list-style-type: none;
}

:focus {
  outline: 0;
}

* {
  box-sizing: border-box;
}
